import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { alpha, createTheme, useTheme, PaletteColorOptions, ThemeProvider, CssBaseline, Box, Alert, AlertTitle, CircularProgress } from '@mui/material';
import './App.css';
import MuffinContainer from '../MuffinContainer/MuffinContainer.tsx'
import ResponsiveAppBar from '../ResponsiveAppBar/ResponsiveAppBar.tsx'
import FixedFooter from '../FixedFooter/FixedFooter.tsx'
import PageContainer from '../PageContainer/PageContainer.tsx'
import GamesPage from '../../pages/GamesPage/GamesPage.tsx'
import HomePage from '../../pages/HomePage/HomePage.tsx'
import LoginPage from '../../pages/LoginPage/LoginPage.tsx'
import GuestPage from '../../pages/GuestPage/GuestPage.tsx'
import RegistrationPage from '../../pages/RegistrationPage/RegistrationPage.tsx'
import ProfilePage from '../../pages/ProfilePage/ProfilePage.tsx';
import GamePage from '../../pages/GamePage/GamePage.tsx';
import GameCreate from '../../pages/GameCreate/GameCreate.tsx';
import AdminPanel from '../../pages/AdminPanel/AdminPanel.tsx';
import { CustomError } from '../../model/CustomError.tsx';
import { Validate } from '../../model/Validate.tsx';
import { _validate } from '../../service/ApiClient.tsx';
import { isAdmin, isModder, isMuffin } from '../../utils/UserUtils.tsx';
import ManageUsers from '../../pages/AdminPanel/Users/ManageUsers.tsx';
import ManageAccess from '../../pages/AdminPanel/Access/ManageAccess.tsx';
import UserDetails from '../../pages/AdminPanel/Users/UserDetails.tsx';

declare module '@mui/material/styles' {
  interface CustomPalette {
    purple_A7: PaletteColorOptions;
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPalette { }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    purple_A7: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    purple_A7: true;
  }
}

const doValidateUser = async (setError: Function, setValidate: Function, navTo: Function) => {
  try {
    let validateResp = await _validate('/users/validate');
    setValidate(validateResp.data);
  } catch (error) {
    if (error.response.data.message) {
      setError({ field: 'gay', message: error.response.data.message });
    }
    if (error.response.data.error) {
      setError({ field: 'gay', message: error.response.data.error });
    }
    localStorage.clear();
    navTo("/login")
  }
}

function App() {

  // validation states
  const [validationError, setValidationError] = useState<CustomError>();
  const [validateResponse, setValidateResponse] = useState<Validate>();

  // nav state
  const navigateTo = useNavigate();

  const accessToken = localStorage.getItem("user_token");

  const theme = useTheme()

  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

  const customeTheme = createTheme({
    palette: {
      purple_A7: createColor(alpha(theme.palette.secondary.dark, 0.70)),
      background: {
        default: '#d6d6d6',
        paper: '#d6d6d6'
      }
    }
  });

  useEffect(() => {
    doValidateUser(setValidationError, setValidateResponse, navigateTo);
  }, [navigateTo, accessToken])

  return (
    <ThemeProvider theme={customeTheme}>
      <CssBaseline />
      <MuffinContainer>
        <ResponsiveAppBar roles={validateResponse?.roles} />
        {validationError && validateResponse && !accessToken &&
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
            <Alert severity='error'>
              <AlertTitle>Something went wrong, please login again!</AlertTitle>
            </Alert>
          </Box>
        }
        {!validationError && !validateResponse &&
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 2,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}>
            <CircularProgress color="secondary" />
          </Box>
        }
        <Routes>
          {!validationError && validateResponse && <>
            {isMuffin(validateResponse.roles) && <>
              <Route index path='/home' element={<PageContainer><HomePage roles={validateResponse.roles} /></PageContainer>} />
              <Route path='/games' element={<PageContainer><GamesPage roles={validateResponse.roles} /></PageContainer>} />
              <Route path='/game/:gameId' element={<PageContainer><GamePage roles={validateResponse.roles} /></PageContainer>} />
              <Route path='/profile' element={<PageContainer><ProfilePage roles={validateResponse.roles} /></PageContainer>} />
            </>}
            {isModder(validateResponse.roles) && <>
              <Route path='/game/create' element={<PageContainer><GameCreate roles={validateResponse.roles} /></PageContainer>} />
            </>}
            {isAdmin(validateResponse.roles) && <>
              <Route path='/acp' element={<PageContainer><AdminPanel roles={validateResponse.roles} /></PageContainer>} />
              <Route path='/acp/manage/users' element={<PageContainer><ManageUsers roles={validateResponse.roles} /></PageContainer>} />
              <Route path='/acp/manage/users/:userId' element={<PageContainer><UserDetails roles={validateResponse.roles} /></PageContainer>} />
              <Route path='/acp/manage/accesses' element={<PageContainer><ManageAccess roles={validateResponse.roles} /></PageContainer>} />
            </>}
          </>}
          {validationError && !validateResponse && <>
            <Route index path='/' element={<PageContainer><GuestPage /></PageContainer>} />
            <Route path='/login' element={<PageContainer><LoginPage /></PageContainer>} />
            <Route path='/register' element={<PageContainer><RegistrationPage /></PageContainer>} />
          </>}
        </Routes>
        <FixedFooter />
      </MuffinContainer>
    </ThemeProvider>
  );
}

export default App;