import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { MyCustomBox } from '../../../components/MyCustomBox/MyCustomBox.tsx';
import { isAdmin } from '../../../utils/UserUtils.tsx';
import { useParams } from 'react-router';

function UserDetails({ roles }) {

    // game states
    const { userId } = useParams();

    return (
        <MyCustomBox>
            {!roles &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <CircularProgress color="secondary" />
                </Box>
            }
            {roles && <>
                {isAdmin(roles) && <>
                    {userId}
                </>}
            </>}
        </MyCustomBox>
    );
};

export default UserDetails;