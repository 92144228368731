import React, { useEffect, useState } from 'react';
import { isAdmin } from '../../../utils/UserUtils.tsx';
import { MyCustomBox } from '../../../components/MyCustomBox/MyCustomBox.tsx';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { useNavigate } from 'react-router';
import { Search, SearchIconWrapper, SearchInputBase } from '../../../components/Search/Search.tsx';
import { SearchOutlined } from '@mui/icons-material';
import { _get } from '../../../service/ApiClient.tsx';
import { CustomError } from '../../../model/CustomError.tsx';
import { Box, Alert, AlertTitle } from '@mui/material';

const users_table_columns = [
    {
        field: 'username',
        headerName: 'Username',
        description: 'Username of the user',
        minWidth: 90,
        renderHeader: (params: any) => (<strong>Username</strong>)
    },
    {
        field: 'email',
        headerName: 'Email',
        description: 'Email of the user',
        minWidth: 180,
        renderHeader: (params: any) => (<strong>Email</strong>)
    }
];

const doGetUsers = async (setUsersList: Function, setError: Function) => {
    try {
        const response = await _get('/users');
        setUsersList(response.data);
    } catch (error) {
        if (error.response.data.message) {
            setError({ field: error.response.data.field, message: error.response.data.message });
        }
        if (error.response.data.error) {
            setError({ field: 'gay', message: error.response.data.error });
        }
    }
};

const filterData = (query: any, data: any) => {
    if (!query) {
        return data;
    } else {
        return data.filter((d: any) => d.username.toLowerCase().includes(query) || d.email.toLowerCase().includes(query));
    }
};

function ManageUsers({ roles }) {

    // generic states
    const navigateTo = useNavigate();

    // validation states
    const [myError, setMyError] = useState<CustomError>();

    const [usersList, setUsersList] = useState<any[]>();
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

    // search state
    const [searchQuery, setSearchQuery] = useState("");
    const dataFiltered = filterData(searchQuery, usersList);

    const onRowsSelectionHandler = (ids: GridRowSelectionModel) => {
        const selectedRowsData = ids.map((id) => usersList?.find((row) => row.id === id));
        setSelectedUsers(selectedRowsData)
    };

    const onRowDoubleClickHandler = (row: any) => {
        navigateTo(`/acp/manage/users/${row.id}`)
    }

    useEffect(() => {
        doGetUsers(setUsersList, setMyError);
    }, [roles])

    return (
        <MyCustomBox>
            {myError &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <Alert severity='error'>
                        <AlertTitle>Something went wrong</AlertTitle>
                        {myError.message}
                    </Alert>
                </Box>
            }
            {roles && <>
                {isAdmin(roles) && <>
                    <Search onInput={(e: any) => { setSearchQuery(e.target.value); }}>
                        <SearchIconWrapper>
                            <SearchOutlined />
                        </SearchIconWrapper>
                        <SearchInputBase
                            fullWidth
                            placeholder="Search user by username or email…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <DataGrid
                        autoHeight
                        rows={dataFiltered}
                        columns={users_table_columns}
                        onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                        onRowDoubleClick={(row) => onRowDoubleClickHandler(row)}
                        sx={{
                            boxShadow: 2,
                            border: 2,
                            marginTop: 2,
                            borderColor: 'secondary.dark',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'secondary.dark',
                            },
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </>}
            </>}
        </MyCustomBox>
    );
};

export default ManageUsers;