import { styled } from '@mui/material/styles';

const MyCustomBox = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 5,
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
        marginLeft: theme.spacing(20),
        width: 'auto',
    },
    [theme.breakpoints.only('md')]: {
        marginRight: theme.spacing(14),
        marginLeft: theme.spacing(14),
        width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        width: 'auto',
    },
}));

export {MyCustomBox}