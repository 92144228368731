import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { MyCustomBox } from '../../components/MyCustomBox/MyCustomBox.tsx';
import { isMuffin } from '../../utils/UserUtils.tsx';

function ProfilePage({ roles }) {

    return (
        <MyCustomBox>
            {!roles &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <CircularProgress color="secondary" />
                </Box>
            }
            {roles && <>
                {isMuffin(roles) && <>
                    <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                        <Typography component='a'>User: {localStorage.getItem('user_name')}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                        <Typography component='a'>Email: {localStorage.getItem('user_email')}</Typography>
                    </Box>
                </>}
            </>}
        </MyCustomBox>
    );
};

export default ProfilePage;