import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const HomeItem = styled(Button)(({ theme }) => ({
    ...theme.typography.h5,
    padding: theme.spacing(1),
    width: 150,
    height: 150,
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center'
}));

export {HomeItem};