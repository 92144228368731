import { Paper, Typography, Link } from '@mui/material';
import React from 'react';
import { styled, alpha } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    backgroundColor: alpha(theme.palette.secondary.dark, 0.70),
    width: '100%'
}));

function FixedFooter() {

    return (
        <StyledPaper square variant="outlined" >
            <Typography
                variant="body2"
                color="white"
                align="center"
            >
                {"Copyright © "}
                <Link color="inherit" target="_blank" href="https://github.com/LaughingMuffin">
                    The Muffinator - Laughing Muffin
                </Link>{" - "}
                {new Date().getFullYear()}
                {""}
            </Typography>
        </StyledPaper>
    );
}

export default FixedFooter;