import {
    Alert, AlertTitle, Box, Breadcrumbs, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup,
    Grid, InputLabel, Link, ListItemText, MenuItem, OutlinedInput, Paper, Select, Switch, TextareaAutosize, TextField, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { _get, _put } from '../../service/ApiClient.tsx';
import { BOOL_VARS, POSSIBLE_GAME_ARCH, POSSIBLE_GAME_LANGUAGE, POSSIBLE_GAME_CATEGORY } from '../../model/Game.tsx';
import { CustomError } from '../../model/CustomError.tsx';
import { MyCustomBox } from '../../components/MyCustomBox/MyCustomBox.tsx';
import { isAdmin, isModder, isMuffin } from '../../utils/UserUtils.tsx';
import { parseIconUrl } from '../../utils/GameUtils.tsx';

const doGetGame = async (setGame: Function, gameId: string, setError: Function) => {
    try {
        const response = await _get(`/games/${gameId}`);
        setGame(response.data);
    } catch (error) {
        if (error.response.data.message) {
            setError({ field: error.response.data.field, message: error.response.data.message });
        }
        if (error.response.data.error) {
            setError({ field: 'gay', message: error.response.data.error });
        }
    }
};

const doUpdateGame = async (game: any, setError: Function, navTo: Function) => {
    try {
        const formData = new FormData();
        formData.append('game', new Blob([JSON.stringify(game)], { type: "application/json" }));
        const headers = {
            "Content-type": "multipart/form-data",
        }
        console.log(game)
        const response = await _put(`/games/${game.id}/update`, formData, headers);
        if (response) {
            navTo(0);
        }
    } catch (error) {
        if (error.response.data.message) {
            setError({ field: error.response.data.field, message: error.response.data.message });
        }
        if (error.response.data.error) {
            setError({ field: 'gay', message: error.response.data.error });
        }
    }
};

const doResetAction = async (gameId: string | undefined, setError: Function, navTo: Function) => {
    try {
        const response = await _put(`/games/${gameId}/reset`);
        if (response) {
            navTo(0);
        }
    } catch (error) {
        if (error.response.data.message) {
            setError({ field: error.response.data.field, message: error.response.data.message });
        }
        if (error.response.data.error) {
            setError({ field: 'gay', message: error.response.data.error });
        }
    }
}

function GamePage({ roles }) {

    // validation states
    const [myError, setMyError] = useState<CustomError>();

    // nav state
    const navigateTo = useNavigate();

    // game states
    const { gameId } = useParams();
    const [game, setGame] = useState<any>(null);
    const [gameIcon, setGameIcon] = useState(null);
    const [gameFeatures, setGameFeatures] = useState('');
    const [gameDownload, setGameDownload] = useState<any>();
    const [gameArchs, setGameArchs] = useState<string[]>([]);
    const [gameLanguages, setGameLanguages] = useState<string[]>([]);
    const [gameCategories, setGameCategories] = useState<string[]>([]);


    // handles
    const handleChangeList = (event: any, propKey: any) => {
        const { target: { value }, } = event;
        switch (propKey) {
            case 'categories':
                setGameCategories(value);
                break;
            case 'languages':
                setGameLanguages(value);
                break;
            case 'arches':
                setGameArchs(value);
                break;
            default:
                console.log('Ayo gay!')
                break;
        }
    };

    const handleChange = (event: any, propKey: any) => {
        const { target: { value, checked }, } = event;
        if (BOOL_VARS.indexOf(propKey) > -1) {
            game[propKey] = checked;
        } else {
            switch (propKey) {
                case 'iconUrl':
                    setGameIcon(value);
                    break;
                case 'dlName':
                    setGameDownload({ ...gameDownload, fileName: value })
                    break;
                case 'dlUrl':
                    setGameDownload({ ...gameDownload, fileUrl: value })
                    break;
                default:
                    game[propKey] = value;
            }
        }
        setGame(game);
    };

    const handleResetAction = (event: any) => {
        doResetAction(gameId, setMyError, navigateTo);
    }

    useEffect(() => {
        if (isMuffin(roles)) {
            doGetGame(setGame, gameId!, setMyError);
        }
    }, [roles, gameId])

    useEffect(() => {
        setGameIcon(game?.iconUrl);
        setGameArchs(game?.arches || POSSIBLE_GAME_ARCH);
        setGameLanguages(game?.languages || POSSIBLE_GAME_LANGUAGE);
        setGameCategories(game?.categories || POSSIBLE_GAME_CATEGORY);
        setGameDownload(game?.downloads[0]);
        setGameFeatures(game?.features.join(','));
    }, [game]);

    const doPrintMyShit = () => {
        game.iconUrl = gameIcon;
        game.arches = gameArchs;
        game.languages = gameLanguages;
        game.categories = gameCategories;
        game.features = gameFeatures.split(',');
        if (gameDownload) {
            game.downloads[0] = gameDownload;
        }
        doUpdateGame(game, setMyError, navigateTo);
    }

    return (
        <MyCustomBox>
            {myError &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <Alert severity='error'>
                        <AlertTitle>Something went wrong</AlertTitle>
                        {myError.message}
                    </Alert>
                </Box>
            }
            {!myError && !roles &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}>
                    <CircularProgress color="secondary" />
                </Box>
            }
            {!myError && roles && game && <>
                {isModder(roles) && <>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link underline='hover' color='text.primary' onClick={() => navigateTo('/games')}> Games List </Link>
                        <Typography color='text.primary'>{game?.title}</Typography>
                    </Breadcrumbs>
                    <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                <img key={new Date().getTime()} height={150} width={150} alt='game icon' src={parseIconUrl(game?.iconUrl)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <TextField
                                    color='secondary'
                                    required label='Title' id='title'
                                    defaultValue={game?.title}
                                    name='title'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'title')}
                                    error={Boolean(myError?.field === 'title')}
                                    helperText={Boolean(myError?.field === 'title') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <TextField
                                    color='secondary'
                                    required
                                    label='Package Name'
                                    id='packageName'
                                    defaultValue={game?.packageName || ''}
                                    name='packageName'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'packageName')}
                                    error={Boolean(myError?.field === 'packageName')}
                                    helperText={Boolean(myError?.field === 'packageName') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <TextField
                                    color='secondary'
                                    required
                                    label='Version'
                                    id='version'
                                    defaultValue={game?.version || ''}
                                    name='version'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'version')}
                                    error={Boolean(myError?.field === 'version')}
                                    helperText={Boolean(myError?.field === 'version') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField color='secondary'
                                    required label='Icon Url'
                                    id='iconUrl'
                                    defaultValue={game?.iconUrl || ''}
                                    name='iconUrl'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'iconUrl')}
                                    error={Boolean(myError?.field === 'iconUrl')}
                                    helperText={Boolean(myError?.field === 'iconUrl') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    color='secondary'
                                    label='Post Url'
                                    id='postUrl'
                                    defaultValue={game?.postUrl || ''}
                                    name='postUrl'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'postUrl')}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                    <InputLabel id='arch-input-label'>Select target architecture</InputLabel>
                                    <Select
                                        labelId='arch-input-multiple-checkbox-label'
                                        id='arch-input-multiple-checkbox'
                                        multiple
                                        value={gameArchs}
                                        onChange={(event) => handleChangeList(event, 'arches')}
                                        input={<OutlinedInput label='Select target architectures' />}
                                        error={Boolean(myError?.field === 'arches')}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {POSSIBLE_GAME_ARCH.map((arch) => (
                                            <MenuItem key={arch} value={arch}>
                                                <Checkbox checked={gameArchs?.indexOf(arch) > -1} />
                                                <ListItemText primary={arch} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                    <InputLabel id='language-input-label'>Select target languages</InputLabel>
                                    <Select
                                        labelId='language-input-multiple-checkbox-label'
                                        id='language-input-multiple-checkbox'
                                        multiple
                                        value={gameLanguages}
                                        onChange={(event) => handleChangeList(event, 'languages')}
                                        input={<OutlinedInput label='Select target languages' />}
                                        error={Boolean(myError?.field === 'languages')}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {POSSIBLE_GAME_LANGUAGE.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                                <Checkbox checked={gameLanguages?.indexOf(lang) > -1} />
                                                <ListItemText primary={lang} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                    <InputLabel id='category-input-label'>Select target categories</InputLabel>
                                    <Select
                                        labelId='category-input-multiple-checkbox-label'
                                        id='category-input-multiple-checkbox'
                                        multiple
                                        value={gameCategories}
                                        onChange={(event) => handleChangeList(event, 'categories')}
                                        input={<OutlinedInput label='Select target categories' />}
                                        error={Boolean(myError?.field === 'categories')}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {POSSIBLE_GAME_CATEGORY.map((cat) => (
                                            <MenuItem key={cat} value={cat}>
                                                <Checkbox checked={gameCategories?.indexOf(cat) > -1} />
                                                <ListItemText primary={cat} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        value='isEnabled'
                                        control={<Checkbox
                                            defaultChecked={game?.enabled}
                                            color='secondary' />}
                                        label='Enabled'
                                        onChange={(event) => handleChange(event, 'enabled')}
                                        labelPlacement='top' />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        value='isR18'
                                        control={<Checkbox
                                            defaultChecked={game?.ageRestricted}
                                            color='secondary' />}
                                        label='R18'
                                        onChange={(event) => handleChange(event, 'ageRestricted')}
                                        labelPlacement='top' />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        value='isFeatured'
                                        control={<Checkbox
                                            defaultChecked={game?.featured}
                                            color='secondary' />}
                                        label='Featured'
                                        onChange={(event) => handleChange(event, 'featured')}
                                        labelPlacement='top' />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography key='features-label' variant='h6'>Feature(s):</Typography>
                                <Typography key='features-label-desc' variant='p'>Values must be comma (,) separated</Typography>
                                {gameFeatures &&
                                    <TextareaAutosize
                                        minRows={5}
                                        style={{ width: '100%', backgroundColor: '#d6d6d6' }}
                                        onChange={(event) => setGameFeatures(event.target.value)}
                                        defaultValue={gameFeatures} />
                                }
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography key='files-label' variant='h6'>File(s):</Typography>
                                <FormControl component='fieldset'>
                                    <FormControlLabel
                                        control={<Switch defaultChecked={game?.root} disabled={game?.root === true ? true : false} color='secondary' />}
                                        onChange={(event) => handleChange(event, 'root')}
                                        label='Root'
                                        labelPlacement='start'
                                    />
                                </FormControl>
                                <Button variant='outlined' color='secondary' sx={{ marginLeft: 5 }} onClick={(event) => handleResetAction(event)}>Reset</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    color='secondary'
                                    label='File Name'
                                    required
                                    id='mainDownloadFileName'
                                    value={gameDownload?.fileName || ''}
                                    name='mainDownloadFileName'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'dlName')}
                                    error={Boolean(myError?.field === 'dlName') || Boolean(myError?.field === 'downloads')}
                                    helperText={Boolean(myError?.field === 'dlName') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    color='secondary'
                                    label='Download Link'
                                    required
                                    id='mainDownloadLink'
                                    value={gameDownload?.fileUrl || ''}
                                    name='mainDownloadLink'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'dlUrl')}
                                    error={Boolean(myError?.field === 'dlUrl') || Boolean(myError?.field === 'downloads')}
                                    helperText={Boolean(myError?.field === 'dlUrl') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button variant='outlined' color='secondary' onClick={() => doPrintMyShit()}>Update</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </>}
                {isMuffin(roles) && (!isModder(roles) || !isAdmin(roles)) && <>
                    <Typography variant='h2'>Coming Soon!</Typography>
                </>}
            </>}
        </MyCustomBox>
    );
};

export default GamePage;