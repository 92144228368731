import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { AddCircleOutline, AdminPanelSettingsOutlined, Person2Outlined, ViewListOutlined } from '@mui/icons-material';
import { MyCustomBox } from '../../components/MyCustomBox/MyCustomBox.tsx';
import { HomeItem } from '../../components/HomeItem/HomeItem.tsx';
import { isAdmin, isModder, isMuffin } from '../../utils/UserUtils.tsx';

function HomePage({ roles }) {

    const navigateTo = useNavigate();

    return (
        <MyCustomBox>
            {roles &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} divider={<Divider orientation="vertical" flexItem />} >
                        {isModder(roles) && <>
                            <HomeItem color='purple_A7' variant='outlined' onClick={() => navigateTo("/game/create")}>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <AddCircleOutline fontSize='large' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        Add Game
                                    </Grid>
                                </Grid>
                            </HomeItem>
                        </>}
                        {isMuffin(roles) && <>
                            <HomeItem color='purple_A7' variant='outlined' onClick={() => navigateTo("/games")}>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <ViewListOutlined fontSize='large' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        Games
                                    </Grid>
                                </Grid>
                            </HomeItem>
                            <HomeItem color='purple_A7' variant='outlined' onClick={() => navigateTo("/profile")}>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Person2Outlined fontSize='large' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        Profile
                                    </Grid>
                                </Grid>
                            </HomeItem>
                        </>}
                        {isAdmin(roles) && <>
                            <HomeItem color='purple_A7' variant='outlined' onClick={() => navigateTo("/acp")}>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <AdminPanelSettingsOutlined fontSize='large' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        ACP
                                    </Grid>
                                </Grid>
                            </HomeItem>
                        </>}
                    </Stack>
                </Box>
            }
        </MyCustomBox>
    );
};

export default HomePage;