import React from "react";
import { Avatar } from "@mui/material";

const parseIconUrl = (iconUrl: string | undefined) => {
    if (iconUrl !== undefined) {
        if (iconUrl.includes('o.qoo-img.com') || iconUrl.includes('play-lh')) {
            return `${iconUrl}250`;
        } else {
            return iconUrl;
        }
    }
    return 'https://placehold.co/250';
}

const games_table_columns = [
    {
        field: 'iconUrl',
        headerName: 'Icon',
        description: 'Game Icon',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 75,
        renderHeader: (params: any) => (<strong>Icon</strong>),
        renderCell: (params: any) => (
            <Avatar alt="Game Icon" src={parseIconUrl(params.value)} sx={{ width: 50, height: 50 }} />
        ),
    },
    {
        field: 'enabled',
        headerName: 'Enabled',
        description: 'Is game enabled',
        minWidth: 75,
        renderHeader: (params: any) => (<strong>Enabled</strong>),
        renderCell: (params: any) => params.value === true ? 'Yes' : 'No'
    },
    {
        field: 'featured',
        headerName: 'Featured',
        description: 'Is game featured',
        minWidth: 75,
        renderHeader: (params: any) => (<strong>Featured</strong>),
        renderCell: (params: any) => params.value === true ? 'Yes' : 'No'
    },
    {
        field: 'title',
        headerName: 'Title',
        description: 'Game Title',
        minWidth: 350,
        renderHeader: (params: any) => (<strong>Title</strong>),
    },
    {
        field: 'version',
        headerName: 'Version',
        description: 'Game Version',
        disableColumnMenu: true,
        minWidth: 100,
        renderHeader: (params: any) => (<strong>Version</strong>),
    },
    {
        field: 'packageName',
        headerName: 'Package',
        description: 'Game Package',
        minWidth: 400,
        renderHeader: (params: any) => (<strong>Package</strong>),
    },
    {
        field: 'ageRestricted',
        headerName: 'R18',
        description: 'Pornish',
        minWidth: 75,
        renderCell: (params: any) => params.value === true ? 'Yes' : 'No',
        renderHeader: (params: any) => (<strong>R18</strong>),
    },
];

export { games_table_columns, parseIconUrl };