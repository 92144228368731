const POSSIBLE_GAME_ARCH = [
    'ARM_V7A',
    'ARM_V8A',
];
const POSSIBLE_GAME_LANGUAGE = [
    'ENGLISH',
    'JAPANESE',
    'KOREAN',
    'CHINESE',
    'VIETNAMESE',
    'THAI',
    'OTHER'
];
const POSSIBLE_GAME_CATEGORY = [
    'ACTION',
    'ADVENTURE',
    'ARCADE',
    'BOARD',
    'CARD',
    'CASINO',
    'CASUAL',
    'EDUCATIONAL',
    'MUSIC',
    'PUZZLE',
    'RACING',
    'ROLE_PLAYING',
    'SIMULATION',
    'SPORTS',
    'STRATEGY',
    'TRIVIA',
    'WORD',
];
const BOOL_VARS = [
    'enabled',
    'ageRestricted',
    'featured',
    'root'
]

export {POSSIBLE_GAME_ARCH, POSSIBLE_GAME_LANGUAGE, POSSIBLE_GAME_CATEGORY, BOOL_VARS}