import React, { useEffect, useState } from 'react';
import { isAdmin } from '../../../utils/UserUtils.tsx';
import { MyCustomBox } from '../../../components/MyCustomBox/MyCustomBox.tsx';
import { DataGrid } from '@mui/x-data-grid';
import { Search, SearchIconWrapper, SearchInputBase } from '../../../components/Search/Search.tsx';
import { SearchOutlined } from '@mui/icons-material';
import { _get } from '../../../service/ApiClient.tsx';
import { CustomError } from '../../../model/CustomError.tsx';
import { Box, Alert, AlertTitle } from '@mui/material';

const access_table_columns = [
    {
        field: 'packageName',
        headerName: 'Package Name',
        description: 'Package name of the game user accessed',
        minWidth: 200,
        renderHeader: (params: any) => (<strong>Package Name</strong>)
    },
    {
        field: 'deviceId',
        headerName: 'Device ID',
        description: 'ID of the device of the user',
        minWidth: 200,
        renderHeader: (params: any) => (<strong>Device ID</strong>)
    },
    {
        field: 'deviceSerial',
        headerName: 'Device Serial',
        description: 'Serial of the device of the user',
        minWidth: 200,
        renderHeader: (params: any) => (<strong>Device Serial</strong>)
    },
    {
        field: 'deviceModel',
        headerName: 'Device Model',
        description: 'Model of the device of the user',
        minWidth: 200,
        renderHeader: (params: any) => (<strong>Device Model</strong>)
    },
    {
        field: 'deviceBrand',
        headerName: 'Device Brand',
        description: 'Brand of the device of the user',
        minWidth: 200,
        renderHeader: (params: any) => (<strong>Device Brand</strong>)
    },
    {
        field: 'createdAt',
        headerName: 'Exec Time',
        description: 'Exect Time, whem start has been pressed',
        minWidth: 200,
        renderHeader: (params: any) => (<strong>Exec Time</strong>),
        renderCell: (params: any) => new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(params.value)),
    },
    {
        field: 'validatedAt',
        headerName: 'Validation Time',
        description: 'Validation time, when game has been started',
        minWidth: 200,
        renderHeader: (params: any) => (<strong>Validation Time</strong>),
        renderCell: (params: any) => new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(params.value)),
    }
];

const doGetAccesses = async (setAccessList: Function, setError: Function) => {
    try {
        const response = await _get('/access');
        setAccessList(response.data);
    } catch (error) {
        if (error.response.data.message) {
            setError({ field: error.response.data.field, message: error.response.data.message });
        }
        if (error.response.data.error) {
            setError({ field: 'gay', message: error.response.data.error });
        }
    }
};

const filterData = (query: any, data: any) => {
    if (!query) {
        return data;
    } else {
        return data.filter((d: any) =>
            d.packageName.toLowerCase().includes(query)
            || d.deviceModel.toLowerCase().includes(query)
            || d.deviceSerial.toLowerCase().includes(query));
    }
};

function ManageAccess({ roles }) {

    // validation states
    const [myError, setMyError] = useState<CustomError>();

    const [accessList, setAccessList] = useState<any[]>();

    // search state
    const [searchQuery, setSearchQuery] = useState("");
    const dataFiltered = filterData(searchQuery, accessList);

    useEffect(() => {
        doGetAccesses(setAccessList, setMyError);
    }, [roles])

    return (
        <MyCustomBox>
            {roles && <>
                {myError &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                        <Alert severity='error'>
                            <AlertTitle>Something went wrong</AlertTitle>
                            {myError.message}
                        </Alert>
                    </Box>
                }
                {roles && <>
                    {isAdmin(roles) && <>
                        <Search onInput={(e: any) => { setSearchQuery(e.target.value); }}>
                            <SearchIconWrapper>
                                <SearchOutlined />
                            </SearchIconWrapper>
                            <SearchInputBase
                                fullWidth
                                placeholder="Search by package name or device model/serial…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                        <DataGrid
                            autoHeight
                            rows={dataFiltered}
                            columns={access_table_columns}
                            sx={{
                                boxShadow: 2,
                                border: 2,
                                marginTop: 2,
                                borderColor: 'secondary.dark',
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'secondary.dark',
                                },
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            disableRowSelectionOnClick
                            disableMultipleRowSelection
                        />
                    </>}
                </>}
            </>}
        </MyCustomBox>
    );
};

export default ManageAccess;