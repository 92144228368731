import React from 'react';
import { MyCustomBox } from '../../components/MyCustomBox/MyCustomBox.tsx';
import { isAdmin } from '../../utils/UserUtils.tsx';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { HomeItem } from '../../components/HomeItem/HomeItem.tsx';
import { ManageAccountsOutlined, MenuBookOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';

function AdminPanel({ roles }) {

    const navigateTo = useNavigate();

    return (
        <MyCustomBox>
            {roles && <>
                {isAdmin(roles) && <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} divider={<Divider orientation="vertical" flexItem />} >
                            <HomeItem color='purple_A7' variant='outlined' onClick={() => navigateTo('/acp/manage/users')}>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <ManageAccountsOutlined fontSize='large' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        Users
                                    </Grid>
                                </Grid>
                            </HomeItem>
                            <HomeItem color='purple_A7' variant='outlined' onClick={() => navigateTo('/acp/manage/accesses')}>
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <MenuBookOutlined fontSize='large' />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        Accesses
                                    </Grid>
                                </Grid>
                            </HomeItem>
                        </Stack>
                    </Box>
                </>}
            </>}
        </MyCustomBox>
    );
};

export default AdminPanel;