import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import { _get, _put } from '../../service/ApiClient.tsx';
import { CircularProgress, List, ListItem } from '@mui/material';
import { useNavigate } from 'react-router';
import { Search, SearchIconWrapper, SearchInputBase } from '../../components/Search/Search.tsx';
import { CustomError } from '../../model/CustomError.tsx';
import { MyCustomBox } from '../../components/MyCustomBox/MyCustomBox.tsx';
import { isModder, isMuffin } from '../../utils/UserUtils.tsx';
import { games_table_columns } from '../../utils/GameUtils.tsx';

const doEnableGames = async (setErrors: Function, selectedGames: any) => {
    try {
        await _put('/games/enable?gameIds=' + selectedGames.map(game => game.id));
    } catch (error) {
        setErrors({ field: error.response.data.field, message: error.response.data.message })
    }
};

const doDisableGames = async (setErrors: Function, selectedGames: any) => {
    try {
        await _put('/games/disable?gameIds=' + selectedGames.map(game => game.id));
    } catch (error) {
        setErrors({ field: error.response.data.field, message: error.response.data.message })
    }
};

const doGetGames = async (setGameList: Function, setError: Function) => {
    try {
        const response = await _get('/games/all');
        setGameList(response.data);
    } catch (error) {
        if (error.response.data.message) {
            setError({ field: 'gay', message: error.response.data.message });
        }
        if (error.response.data.error) {
            setError({ field: 'gay', message: error.response.data.error });
        }
    }
};

const filterData = (query: any, data: any) => {
    if (!query) {
        return data;
    } else {
        return data.filter((d: any) => d.title.toLowerCase().includes(query) || d.packageName.toLowerCase().includes(query));
    }
};

function GamesPage({ roles }) {

    // generic states
    const navigateTo = useNavigate();

    // validation states
    const [myError, setMyError] = useState<CustomError>();

    // search state
    const [searchQuery, setSearchQuery] = useState("");

    // games states
    const [gameList, setGameList] = useState<any[]>();
    const [selectedGames, setSelectedGames] = useState<any[]>([]);
    const [openEnableSelected, setOpenEnableSelected] = React.useState(false);
    const [openDisableSelected, setOpenDisableSelected] = React.useState(false);
    const dataFiltered = filterData(searchQuery, gameList);

    // some handles
    const handleClickOpenEnable = () => {
        setOpenEnableSelected(true);
    };

    const handleCloseEnable = () => {
        setOpenEnableSelected(false);
    };

    const handleClickOpenDisable = () => {
        setOpenDisableSelected(true);
    };

    const handleCloseDisable = () => {
        setOpenDisableSelected(false);
    };

    const handleEnableGames = () => {
        doEnableGames(setMyError, selectedGames);
        handleCloseEnable();
        navigateTo(0);
    }

    const handleDisableGames = () => {
        doDisableGames(setMyError, selectedGames);
        handleCloseDisable();
        navigateTo(0);
    }

    // data grid helpers
    const prepareRow = (row: any) => {
        return <ListItem key={row.id}>{row.title}</ListItem>
    }

    const onRowsSelectionHandler = (ids: GridRowSelectionModel) => {
        const selectedRowsData = ids.map((id) => gameList?.find((row) => row.id === id));
        setSelectedGames(selectedRowsData)
    };

    const onRowDoubleClickHandler = (row: any) => {
        navigateTo(`/game/${row.id}`)
    }

    useEffect(() => {
        if (isMuffin(roles)) {
            doGetGames(setGameList, setMyError);
        }
    }, [roles])

    return (
        <MyCustomBox>
            {myError &&
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, bgcolor: 'background.paper', borderRadius: 1, }}>
                    <Alert severity='error'>
                        <AlertTitle>Something went wrong</AlertTitle>
                        {myError.message}
                    </Alert>
                </Box>
            }
            {!myError && !gameList &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}>
                    <CircularProgress color="secondary" />
                </Box>
            }
            {roles && !myError && gameList &&
                <>
                    {isMuffin(roles) &&
                        <Search onInput={(e: any) => { setSearchQuery(e.target.value); }}>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <SearchInputBase
                                fullWidth
                                placeholder="Search game by name or package name…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>}
                    {isModder(roles) && <>
                        <Dialog
                            keepMounted
                            open={openEnableSelected}
                            onClose={handleCloseEnable}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Confirm ENABLE Action"}</DialogTitle>
                            <DialogContent>
                                <Box>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        Are you sure you want to enable the selected games:
                                    </DialogContentText>
                                    <List>
                                        {selectedGames?.map(game => prepareRow(game))}
                                    </List>
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button color="secondary" onClick={handleCloseEnable}>Cancel</Button>
                                <Button color="secondary" onClick={handleEnableGames}>Proceed</Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openDisableSelected}
                            keepMounted
                            onClose={handleCloseDisable}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"Confirm DISABLE Action"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Are you sure you want to disable the selected games:
                                </DialogContentText>
                                <List>
                                    {selectedGames?.map(game => prepareRow(game))}
                                </List>
                            </DialogContent>
                            <DialogActions>
                                <Button color="secondary" onClick={handleCloseDisable}>Cancel</Button>
                                <Button color="secondary" onClick={handleDisableGames}>Proceed</Button>
                            </DialogActions>
                        </Dialog>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 2,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                        }}>
                            <ButtonGroup variant="contained" disableElevation color='purple_A7'>
                                <Button disabled={selectedGames.length === 0} onClick={handleClickOpenEnable}>Enable Selected</Button>
                                <Button onClick={() => navigateTo('/game/create')}>Add Game</Button>
                                <Button disabled={selectedGames.length === 0} onClick={handleClickOpenDisable}>Disable Selected</Button>
                            </ButtonGroup>
                        </Box>
                    </>}
                    {isMuffin(roles) &&
                        <DataGrid
                            autoHeight
                            rows={dataFiltered}
                            columns={games_table_columns}
                            onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                            onRowDoubleClick={(row) => onRowDoubleClickHandler(row)}
                            sx={{
                                boxShadow: 2,
                                border: 2,
                                marginTop: 2,
                                borderColor: 'secondary.dark',
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'secondary.dark',
                                },
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />}
                </>
            }
        </MyCustomBox>
    );
}

export default GamesPage;