import React, { useEffect, useState } from 'react';
import {
    Breadcrumbs, Button, Checkbox, Fade, FormControl, FormControlLabel, FormGroup, Grid, InputLabel,
    ListItemText, MenuItem, OutlinedInput, Paper, Select, Switch, TextareaAutosize, TextField, Tooltip, Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { _post } from '../../service/ApiClient.tsx';
import { MyCustomBox } from '../../components/MyCustomBox/MyCustomBox.tsx';
import { CustomError } from '../../model/CustomError.tsx';
import { isModder } from '../../utils/UserUtils.tsx';
import { parseIconUrl } from '../../utils/GameUtils.tsx';
import { POSSIBLE_GAME_ARCH, POSSIBLE_GAME_CATEGORY, POSSIBLE_GAME_LANGUAGE } from '../../model/Game.tsx';

const doCreateGame = async (game: any, setError: Function, navTo: Function) => {
    try {
        const formData = new FormData();
        formData.append('game', new Blob([JSON.stringify(game)], { type: "application/json" }));
        const headers = {
            "Content-type": "multipart/form-data",
        }
        console.log(formData)
        const response = await _post(`/games/create`, formData, headers);
        if (response) {
            console.log(response.data)
            navTo(`/game/${response.data.id}`);
        }
    } catch (error) {
        if (error.response.data.message) {
            setError({ field: error.response.data.field, message: error.response.data.message });
        }
        if (error.response.data.error) {
            setError({ field: 'gay', message: error.response.data.error });
        }
    }
};

function GameCreate({ roles }) {

    // validation states
    const [myError, setMyError] = useState<CustomError>();

    // game states
    const [gameDownload, setGameDownload] = useState({
        cheatType: 'MOD_APK',
        downloadType: 'EXTERNAL',
        fileName: null,
        fileUrl: null,
        extras: []
    });
    const [game, setGame] = useState({
        title: null,
        packageName: null,
        postUrl: null,
        version: null,
        iconUrl: null,
        featured: false,
        enabled: false,
        ageRestricted: false,
        root: false,
        arches: [],
        categories: [],
        languages: [],
        features: [],
        downloads: [gameDownload]
    });
    const [gameTitle, setGameTitle] = useState<string>();
    const [gameVersion, setGameVersion] = useState<string>();
    const [gamePackageName, setGamePackageName] = useState<string>();
    const [gameIconUrl, setGameIconUrl] = useState<string>();
    const [gamePostUrl, setGamePostUrl] = useState<string>();
    const [gameArchs, setGameArchs] = useState<string[]>([]);
    const [gameLanguages, setGameLanguages] = useState<string[]>([]);
    const [gameCategories, setGameCategories] = useState<string[]>([]);
    const [gameEnabled, setGameEnabled] = useState<boolean>(false);
    const [gameIsRoot, setGameIsRoot] = useState<boolean>(false);
    const [gameFeatured, setGameFeatured] = useState<boolean>(false);
    const [gameAgeRestricted, setGameAgeRestricted] = useState<boolean>(false);
    const [gameFeatures, setGameFeatures] = useState<string>('');
    const [gameDownloadName, setGameDownloadName] = useState<string>();
    const [gameDownloadUrl, setGameDownloadUrl] = useState<string>();

    // nav state
    const navigateTo = useNavigate();

    const handleChangeList = (event: any, propKey: any) => {
        const { target: { value }, } = event;
        switch (propKey) {
            case 'categories':
                setGameCategories(value);
                setGame({ ...game, categories: value })
                break;
            case 'languages':
                setGameLanguages(value);
                setGame({ ...game, languages: value })
                break;
            case 'arches':
                setGameArchs(value);
                setGame({ ...game, arches: value })
                break;
            default:
                console.log('Ayo gay!')
                break;
        }
    };

    const handleChange = (event: any, propKey: any) => {
        const { target: { value, checked }, } = event;
        switch (propKey) {
            case 'iconUrl':
                setGameIconUrl(value);
                setGame({ ...game, iconUrl: value });
                break;
            case 'title':
                setGameTitle(value);
                setGame({ ...game, title: value });
                break;
            case 'packageName':
                setGamePackageName(value);
                setGame({ ...game, packageName: value });
                break;
            case 'version':
                setGameVersion(value);
                setGame({ ...game, version: value });
                break;
            case 'postUrl':
                setGamePostUrl(value);
                setGame({ ...game, postUrl: value });
                break;
            case 'enabled':
                setGameEnabled(checked);
                setGame({ ...game, enabled: checked });
                break;
            case 'root':
                setGameIsRoot(checked);
                setGame({ ...game, root: checked });
                break;
            case 'featured':
                setGameFeatured(checked);
                setGame({ ...game, featured: checked });
                break;
            case 'ageRestricted':
                setGameAgeRestricted(checked);
                setGame({ ...game, ageRestricted: checked });
                break;
            case 'dlName':
                setGameDownloadName(value);
                setGameDownload({ ...gameDownload, fileName: value });
                setGame({ ...game, downloads: [gameDownload] });
                break;
            case 'dlUrl':
                setGameDownloadUrl(value);
                setGameDownload({ ...gameDownload, fileUrl: value });
                setGame({ ...game, downloads: [gameDownload] });
                break;
            default:
                console.log('Are you actually gay !?');
                break;
        }
    };

    const doPrintMyShit = () => {
        doCreateGame(game, setMyError, navigateTo);
    }

    useEffect(() => {
        console.log(myError)
    }, [myError])

    return (
        <MyCustomBox>
            {roles && <>
                {isModder(roles) && <>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link color='text.primary' onClick={() => navigateTo(-1)} to={''}> Games List </Link>
                        <Typography color='text.primary'>Create Game</Typography>
                    </Breadcrumbs>
                    <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        <Grid container component='form' spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                <img key={new Date().getTime()} height={150} width={150} alt='game icon' src={parseIconUrl(gameIconUrl)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <TextField
                                    color='secondary'
                                    required label='Title' id='title'
                                    defaultValue={gameTitle}
                                    name='title'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'title')}
                                    error={Boolean(myError?.field === 'title')}
                                    helperText={Boolean(myError?.field === 'title') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5}>
                                <TextField
                                    color='secondary'
                                    required
                                    label='Package Name'
                                    id='packageName'
                                    defaultValue={gamePackageName}
                                    name='packageName'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'packageName')}
                                    error={Boolean(myError?.field === 'packageName')}
                                    helperText={Boolean(myError?.field === 'packageName') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <TextField
                                    color='secondary'
                                    required
                                    label='Version'
                                    id='version'
                                    defaultValue={gameVersion}
                                    name='version'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'version')}
                                    error={Boolean(myError?.field === 'version')}
                                    helperText={Boolean(myError?.field === 'version') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}
                                    title="Google Play or QooApp icon, please do not include last part (ex. 48-rw {Google Play} or 192 {QooApp}) as for other source please use Imgur (ex. https://i.imgur.com/GGCYtJY.png)."
                                    placement="top" arrow>
                                    <TextField color='secondary'
                                        required label='Icon Url'
                                        id='iconUrl'
                                        defaultValue={gameIconUrl}
                                        name='iconUrl'
                                        fullWidth
                                        onChange={(event) => handleChange(event, 'iconUrl')}
                                        error={Boolean(myError?.field === 'iconUrl')}
                                        helperText={Boolean(myError?.field === 'iconUrl') ? myError?.message : ''}
                                        variant='standard' />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    color='secondary'
                                    label='Post Url'
                                    id='postUrl'
                                    defaultValue={gamePostUrl}
                                    name='postUrl'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'postUrl')}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                    <InputLabel id='arch-input-label'>Select target architecture</InputLabel>
                                    <Select
                                        labelId='arch-input-multiple-checkbox-label'
                                        id='arch-input-multiple-checkbox'
                                        multiple
                                        value={gameArchs}
                                        onChange={(event) => handleChangeList(event, 'arches')}
                                        input={<OutlinedInput label='Select target architectures' />}
                                        error={Boolean(myError?.field === 'arches')}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {POSSIBLE_GAME_ARCH.map((arch) => (
                                            <MenuItem key={arch} value={arch}>
                                                <Checkbox checked={gameArchs?.indexOf(arch) > -1} />
                                                <ListItemText primary={arch} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {Boolean(myError?.field === 'arches') && <Typography color={'#d50000'}>{myError?.message}</Typography>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                    <InputLabel id='language-input-label'>Select target languages</InputLabel>
                                    <Select
                                        labelId='language-input-multiple-checkbox-label'
                                        id='language-input-multiple-checkbox'
                                        multiple
                                        value={gameLanguages}
                                        onChange={(event) => handleChangeList(event, 'languages')}
                                        input={<OutlinedInput label='Select target languages' />}
                                        error={Boolean(myError?.field === 'languages')}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {POSSIBLE_GAME_LANGUAGE.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                                <Checkbox checked={gameLanguages?.indexOf(lang) > -1} />
                                                <ListItemText primary={lang} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {Boolean(myError?.field === 'languages') && <Typography color={'#d50000'}>{myError?.message}</Typography>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl color='secondary' required sx={{ m: 1, width: 350 }}>
                                    <InputLabel id='category-input-label'>Select target categories</InputLabel>
                                    <Select
                                        labelId='category-input-multiple-checkbox-label'
                                        id='category-input-multiple-checkbox'
                                        multiple
                                        value={gameCategories}
                                        onChange={(event) => handleChangeList(event, 'categories')}
                                        input={<OutlinedInput label='Select target categories' />}
                                        error={Boolean(myError?.field === 'categories')}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {POSSIBLE_GAME_CATEGORY.map((cat) => (
                                            <MenuItem key={cat} value={cat}>
                                                <Checkbox checked={gameCategories?.indexOf(cat) > -1} />
                                                <ListItemText primary={cat} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {Boolean(myError?.field === 'categories') && <Typography color={'#d50000'}>{myError?.message}</Typography>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        value='isEnabled'
                                        control={<Checkbox
                                            checked={gameEnabled}
                                            color='secondary' />}
                                        label='Enabled'
                                        onChange={(event) => handleChange(event, 'enabled')}
                                        labelPlacement='top' />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        value='isR18'
                                        control={<Checkbox
                                            checked={gameAgeRestricted}
                                            color='secondary' />}
                                        label='R18'
                                        onChange={(event) => handleChange(event, 'ageRestricted')}
                                        labelPlacement='top' />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                                <FormGroup>
                                    <FormControlLabel
                                        value='isFeatured'
                                        control={<Checkbox
                                            checked={gameFeatured}
                                            color='secondary' />}
                                        label='Featured'
                                        onChange={(event) => handleChange(event, 'featured')}
                                        labelPlacement='top' />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography key='features-label' variant='h6'>Features:</Typography>
                                <Typography key='features-label-desc' variant='p'>Values must be comma (,) separated</Typography>
                                <TextareaAutosize
                                    minRows={5}
                                    style={{ width: '100%', backgroundColor: '#d6d6d6' }}
                                    onChange={(event) => {
                                        setGameFeatures(event.target.value);
                                        setGame({ ...game, features: event.target.value.split(',') });
                                    }}
                                    defaultValue={gameFeatures} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography key='files-label' variant='h6'>Files:</Typography>
                                <FormControl component='fieldset'>
                                    <FormControlLabel
                                        control={<Switch color='secondary' />}
                                        checked={gameIsRoot}
                                        onChange={(event) => handleChange(event, 'root')}
                                        label='Root'
                                        labelPlacement='start'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    color='secondary'
                                    label='File Name'
                                    required
                                    id='mainDownloadFileName'
                                    defaultValue={gameDownloadName}
                                    name='mainDownloadFileName'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'dlName')}
                                    error={Boolean(myError?.field === 'dlName') || Boolean(myError?.field === 'downloads')}
                                    helperText={Boolean(myError?.field === 'dlName') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    color='secondary'
                                    label='Download Link'
                                    required
                                    id='mainDownloadLink'
                                    defaultValue={gameDownloadUrl}
                                    name='mainDownloadLink'
                                    fullWidth
                                    onChange={(event) => handleChange(event, 'dlUrl')}
                                    error={Boolean(myError?.field === 'dlUrl') || Boolean(myError?.field === 'downloads')}
                                    helperText={Boolean(myError?.field === 'dlUrl') ? myError?.message : ''}
                                    variant='standard' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button variant='outlined' color='secondary' onClick={() => doPrintMyShit()}>Create Game</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </>}
            </>}
        </MyCustomBox>
    );
};

export default GameCreate;