import { InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.secondary.dark, 0.60),
    '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.dark, 0.30),
    },
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(20),
        marginLeft: theme.spacing(20),
        width: 'auto',
    },
    [theme.breakpoints.only('md')]: {
        marginRight: theme.spacing(14),
        marginLeft: theme.spacing(14),
        width: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
        marginRight: theme.spacing(8),
        marginLeft: theme.spacing(8),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const SearchInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
}));

export {Search, SearchIconWrapper, SearchInputBase};