import React, { useState } from 'react';
import { AppBar, Box, Toolbar, IconButton, Typography, Container, Avatar, Collapse, Divider, Menu, MenuItem } from '@mui/material';
import { Icon } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import { styled, alpha } from '@mui/material/styles';
import { AddOutlined, AdminPanelSettingsOutlined, AppRegistrationOutlined, ExpandLessOutlined, ExpandMoreOutlined, LoginOutlined, LogoutOutlined, ManageAccountsOutlined, MenuBookOutlined, Person2Outlined, SettingsOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { isAdmin, isLogged, isModder, isMuffin } from '../../utils/UserUtils.tsx';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'sticky',
  backgroundColor: alpha(theme.palette.secondary.dark, 0.70),
}));

function ResponsiveAppBar({ roles }) {

  const navigateTo = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNormalDropdown = Boolean(anchorEl);

  const toggleDrawer = (open: any) => {
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    localStorage.removeItem("user_token");
    navigateTo("/");
    navigateTo(0);
  }

  const handleGoToGames = () => {
    toggleDrawer(false);
    navigateTo("/games");
  }

  const handleGoToProfile = () => {
    toggleDrawer(false);
    navigateTo("/profile");
  }

  const handleGoToCreateGame = () => {
    toggleDrawer(false)
    navigateTo("/game/create")
  }

  const handleGoToACP = () => {
    toggleDrawer(false);
    setAnchorEl(null);
    navigateTo("/acp");
  }

  const handleGoToACPUsers = () => {
    toggleDrawer(false);
    setAnchorEl(null);
    navigateTo("/acp/manage/users");
  }

  const handleGoToACPAccesses = () => {
    toggleDrawer(false);
    setAnchorEl(null);
    navigateTo("/acp/manage/accesses");
  }

  const handleGoToLogin = () => {
    toggleDrawer(false);
    navigateTo("/login");
  }

  const handleGoToRegister = () => {
    toggleDrawer(false);
    navigateTo("/register");
  }

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickNormalDropDown = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseNormalDropdown = () => {
    setAnchorEl(null);
  };

  const list = () => (
    <Box sx={{ width: 250 }} role="presentation" onKeyDown={() => toggleDrawer(false)}>
      <List >
        {roles && <>
          {isAdmin(roles) && <>
            <ListItem onClick={() => handleClick()}>
              <ListItemIcon><AdminPanelSettingsOutlined /></ListItemIcon>
              <ListItemText primary='Admin Panel' />
              {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleGoToACP()}>
                    <ListItemIcon>
                      <SettingsOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleGoToACPUsers()}>
                    <ListItemIcon>
                      <ManageAccountsOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Manage Users" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleGoToACPAccesses()}>
                    <ListItemIcon>
                      <MenuBookOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Manager Accesses" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <Divider />
          </>
          }
          {isModder(roles) &&
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleGoToCreateGame()}>
                <ListItemIcon>
                  <AddOutlined />
                </ListItemIcon>
                <ListItemText primary="Create Game" />
              </ListItemButton>
            </ListItem>
          }
          {isMuffin(roles) &&
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleGoToGames()}>
                <ListItemIcon>
                  <VideogameAssetIcon />
                </ListItemIcon>
                <ListItemText primary="Games List" />
              </ListItemButton>
            </ListItem>
          }
          {isLogged(roles) && <>
            <Divider />
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleGoToProfile()}>
                <ListItemIcon>
                  <Person2Outlined />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleLogout()}>
                <ListItemIcon>
                  <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
            <Divider />
          </>}
        </>}
        {(!roles) && <>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleGoToLogin()}>
              <ListItemIcon>
                <LoginOutlined />
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleGoToRegister()}>
              <ListItemIcon>
                <AppRegistrationOutlined />
              </ListItemIcon>
              <ListItemText primary="Sign Up" />
            </ListItemButton>
          </ListItem>
        </>}
      </List>
    </Box>
  );

  return (
    <StyledAppBar>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Icon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}><img src="../../../fav_icon.png" alt='Logo' /></Icon>
          <Typography variant="h6" noWrap component="a" href="/home"
            sx={{
              mr: 2, display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace', fontWeight: 700,
              letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none',
            }}>
            MUFFINATOR
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar"
              aria-haspopup="true" onClick={() => toggleDrawer(true)} color="inherit">
              <MenuIcon />
            </IconButton>
            <Drawer anchor={"left"} open={drawerOpen} onClose={() => toggleDrawer(false)}>
              {list()}
            </Drawer>
          </Box>
          <Icon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}><img src="../../../fav_icon.png" alt='Logo' /></Icon>
          <Typography variant="h5" noWrap component="a" href="/home"
            sx={{
              mr: 2, display: { xs: 'flex', md: 'none' }, flexGrow: 1,
              fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem',
              color: 'inherit', textDecoration: 'none',
            }}>
            MUFFINATOR
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {roles && <>
              {isAdmin(roles) && <>
                <Button id="basic-button"
                  aria-controls={openNormalDropdown ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openNormalDropdown ? 'true' : undefined}
                  sx={{ my: 2, color: 'white', display: 'block' }} onClick={(event) => handleClickNormalDropDown(event)}>
                  Admin Panel
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openNormalDropdown}
                  onClose={handleCloseNormalDropdown}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => handleGoToACP()}>Settings</MenuItem>
                  <MenuItem onClick={() => handleGoToACPUsers()}>Manage Users</MenuItem>
                  <MenuItem onClick={() => handleGoToACPAccesses()}>Manager Accesses</MenuItem>
                </Menu>
              </>}
              {isModder(roles) &&
                <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => handleGoToCreateGame()}>
                  Create Game
                </Button>
              }
              {isMuffin(roles) &&
                <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => handleGoToGames()}>
                  Game List
                </Button>
              }
              {isLogged(roles) && <>
                <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => handleGoToProfile()}>
                  Profile
                </Button>
                <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => handleLogout()}>
                  Logout
                </Button>
              </>}
            </>}
            {!roles && <>
              <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => handleGoToLogin()} >
                Login
              </Button>
              <Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={() => handleGoToRegister()}>
                Register
              </Button>
            </>}
          </Box>
          {roles && isLogged(roles) && <Box sx={{ flexGrow: 0 }}>
            <IconButton sx={{ p: 0 }} onClick={() => navigateTo("/profile")}>
              <Avatar alt={localStorage.getItem('user_name') || '?'} srcSet='#empty' variant="rounded" />
            </IconButton>
          </Box>}
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}
export default ResponsiveAppBar;