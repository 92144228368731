const isModder = (userRoles: string[] | undefined) => {
    return userRoles?.find((el: string) => el === 'MODDER') !== undefined;
}

const isAdmin = (userRoles: string[] | undefined) => {
    return userRoles?.find((el: string) => el === 'ADMIN') !== undefined;
}

const isMuffin = (userRoles: string[] | undefined) => {
    return userRoles?.find((el: string) => el === 'MUFFINATOR') !== undefined;
}

const isLogged = (userRoles: string[] | undefined) => {
    return userRoles?.length !== 0;
}

export {isModder, isAdmin, isMuffin, isLogged};