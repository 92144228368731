import { LockOutlined } from '@mui/icons-material';
import { Container, CssBaseline, Box, Avatar, Typography, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import { _login } from '../../service/ApiClient.tsx';
import { useNavigate } from 'react-router';
import { CustomError } from '../../model/CustomError.tsx';

const doLogin = async (email: any, password: any, navigateTo: Function, setErrors: Function) => {
    try {
        const loginData = { email: email, password: password };
        const response = await _login('/login', loginData);
        if (response) {
            localStorage.setItem('user_token', response.data.accessToken);
            localStorage.setItem('user_name', response.data.username);
            localStorage.setItem('user_email', response.data.email);
            navigateTo('/home');
            navigateTo(0);
        }
    } catch (error) {
        if (error.response.data.message) {
            setErrors({ field: error.response.data.field, message: error.response.data.message });
        }
        if (error.response.data.error) {
            setErrors({ field: 'gay', message: error.response.data.error });
        }
    }
};

function LoginPage() {

    const navigateTo = useNavigate();

    const [errors, setErrors] = useState<CustomError>()

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        doLogin(data.get('email'), data.get('password'), navigateTo, setErrors);
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    Sign in
                </Typography>
                <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        color='secondary'
                        id='email'
                        label='Email Address'
                        name='email'
                        autoComplete='email'
                        error={Boolean(errors?.field === 'login')}
                        autoFocus
                    />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        color='secondary'
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        error={Boolean(errors?.field === 'login')}
                        helperText={Boolean(errors?.field === 'login') ? errors?.message : ''}
                        autoComplete='current-password'
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        color='secondary'>
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default LoginPage;