import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router';
import { _register } from '../../service/ApiClient.tsx';
import { CustomError } from '../../model/CustomError.tsx';

const doRegister = async (username: any, email: any, password: any, challengeCode: any, navigateTo: Function, setErrors: Function) => {
    try {
        const regData = { username: username, email: email, password: password, challengeCode: challengeCode };
        const response = await _register('/register', regData);
        if (response) {
            navigateTo('/login');
        }
    } catch (error) {
        if (error.response.data.message) {
            setErrors({ field: error.response.data.field, message: error.response.data.message });
        }
        if (error.response.data.error) {
            setErrors({ field: 'gay', message: error.response.data.error });
        }
    }
};

function RegistrationPage() {

    const navigateTo = useNavigate();

    const [errors, setErrors] = useState<CustomError>()

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        doRegister(data.get('username'), data.get('email'), data.get('password'), data.get('challengeCode'), navigateTo, setErrors);
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    Sign up
                </Typography>
                <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete='username'
                                name='username'
                                required
                                fullWidth
                                color='secondary'
                                id='username'
                                label='Username'
                                error={Boolean(errors?.field === 'username')}
                                helperText={Boolean(errors?.field === 'username') ? errors?.message : ''}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                color='secondary'
                                id='email'
                                label='Email Address'
                                name='email'
                                type='email'
                                error={Boolean(errors?.field === 'email')}
                                helperText={Boolean(errors?.field === 'email') ? errors?.message : ''}
                                autoComplete='email'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                color='secondary'
                                name='password'
                                label='Password'
                                type='password'
                                id='password'
                                error={Boolean(errors?.field === 'password')}
                                helperText={Boolean(errors?.field === 'password') ? errors?.message : ''}
                                autoComplete='new-password'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                color='secondary'
                                name='challengeCode'
                                label='Input the code'
                                type='challengeCode'
                                id='challengeCode'
                                error={Boolean(errors?.field === 'challengeCode')}
                                helperText={Boolean(errors?.field === 'challengeCode') ? errors?.message : ''}
                                autoComplete='challengeCode'
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='secondary'
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <Link color='secondary' href='/login' variant='body2'>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default RegistrationPage;